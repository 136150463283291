import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { themeStyles } from '../utils/theme'
import TitleBar from '../components/TitleBar'

const styles = {
  container: {
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
  },
}

export const FAQPageTemplate = ({
  content,
  description,
  contentComponent,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <TitleBar
        title="FAQs:"
        links={[
          {text: 'General', link: '#general'},
          {text: 'Products', link: '#products'},
          {text: 'Application', link: '#application'},
          {text: 'Support', link: '#support'},
          {text: 'Careers', link: '#careers'},
        ]}
      />
      <section className="section" css={styles.container}>
        {helmet || ''}
        <PostContent content={content} />
      </section>
    </div>
  )
}

FAQPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const FAQPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout metadataDescription={post.frontmatter.description} metadataTitle={post.frontmatter.title}>
      <FAQPageTemplate
        content={post.htmlAst}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default FAQPage

export const pageQuery = graphql`
  query FAQPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        title
        description
      }
    }
  }
`
